// assets
import { IconBuildingEstate, IconUsers, IconCalendarEvent, IconReportAnalytics } from '@tabler/icons';
import ListIcon from '@mui/icons-material/List';

const icons = {
    IconBuildingEstate,
    IconUsers,
    IconCalendarEvent,
    ListIcon,
    IconReportAnalytics
};

const labDbDataPages = {
    id: 'labdb-datas-pages',
    title: 'LABdb data Pages',
    icon: icons.IconKey,
    type: 'group',
    children: [
        {
            id: 'labdb-initial-sales-page',
            title: 'Labdb Initial Sales Datas',
            icon: icons.IconReportAnalytics,
            type: 'item',
            url: '/labdb-initial-sales-page'
        }
    ]
};

export default labDbDataPages;
