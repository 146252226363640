import { useRoutes } from 'react-router-dom';

// routes
import AuthenticationRotes from './AuthenticationRoutes';
import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    // Clear the VP filter stored if not vp related page
    if(!window.location.href.includes("visit-plans-page")) {
        if(sessionStorage?.getItem("staffViewFilter")) {
            sessionStorage.removeItem("staffViewFilter");
        }
        if(sessionStorage?.getItem("managerViewFilter")) {
            sessionStorage.removeItem("managerViewFilter");
        }
    }
    return useRoutes([LoginRoutes, AuthenticationRotes, MainRoutes]);
}
