const baseUrl = () => {
    if (process.env.REACT_APP_IS_ONLINE === 'true') {
        return 'https://api.admin-db.com';
    } else {
        return 'http://127.0.0.1:3333';
    }
};

const API = {
    url: baseUrl()
};

export default API;
