import { lazy } from 'react';

// project imports
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));
const Dashboard = Loadable(lazy(() => import('views/dashboard-page')));

// SALESdb
const FacilitiesPage = Loadable(lazy(() => import('views/facilities-page')));
const FacilityDetailPage = Loadable(lazy(() => import('views/facilities-page/facility-detail')));
const EmployeesPage = Loadable(lazy(() => import('views/persons-page')));
const EmployeeDetailPage = Loadable(lazy(() => import('views/persons-page/person-detail')));
const VisitPlansPage = Loadable(lazy(() => import('views/visit-plans-page')));
const VisitPlansDetailPage = Loadable(lazy(() => import('views/visit-plans-page/visit-plan-detail')));

const VisitPage = Loadable(lazy(() => import('views/visit-page')));
const VisitDetailPage = Loadable(lazy(() => import('views/visit-page/visit-detail')));
const LabdbInitialSalesPage = Loadable(lazy(() => import('views/labdb-initial-sales-page')));
const AdminDashboard = Loadable(lazy(() => import('views/admin-dashboard-page')));
const SettingsPage = Loadable(lazy(() => import('views/settings-page')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/',
            element: <Dashboard />
        },
        {
            path: '/dashboard',
            element: <Dashboard />
        },
        {
            path: '/sample-page',
            element: <SamplePage />
        },
        {
            path: '/facilities-page',
            element: <FacilitiesPage />
        },
        {
            path: '/facilities-page/detail/:facility_id',
            element: <FacilityDetailPage />
        },
        {
            path: '/visit-page',
            element: <VisitPage />
        },
        {
            path: '/visit-page/detail/:visit_id',
            element: <VisitDetailPage />
        },
        {
            path: '/employees-page',
            element: <EmployeesPage />
        },
        {
            path: '/employees-page/detail/:person_id',
            element: <EmployeeDetailPage />
        },
        {
            path: '/visit-plans-page',
            element: <VisitPlansPage />
        },
        {
            path: '/visit-plans-page/detail/:visit_plan_id',
            element: <VisitPlansDetailPage />
        },
        {
            path: '/visit-plans-page/detail/new',
            element: <VisitPlansDetailPage />
        },
        {
            path: '/labdb-initial-sales-page',
            element: <LabdbInitialSalesPage />
        },
        {
            path: '/admin-dashboard-page',
            element: <AdminDashboard />
        },
        {
            path: '/settings-page',
            element: <SettingsPage />
        }
    ]
};

export default MainRoutes;
