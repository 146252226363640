// assets
import { IconBuildingEstate, IconUsers, IconCalendarEvent } from '@tabler/icons';
import ListIcon from '@mui/icons-material/List';

const icons = {
    IconBuildingEstate,
    IconUsers,
    IconCalendarEvent,
    ListIcon
};

const infoPages = {
    id: 'info-pages',
    title: 'SALESdb Pages',
    icon: icons.IconKey,
    type: 'group',
    children: [
        {
            id: 'visit-plans-page',
            title: 'Visit Plans',
            icon: icons.IconCalendarEvent,
            type: 'item',
            url: '/visit-plans-page'
        },
        {
            id: 'visit-page',
            title: 'Visit Records',
            icon: icons.ListIcon,
            type: 'item',
            url: '/visit-page'
        },
        // {
        //     id: 'facilities-page',
        //     title: 'Facilities',
        //     icon: icons.IconBuildingEstate,
        //     type: 'item',
        //     url: '/facilities-page'
        // },
        // {
        //     id: 'employees-page',
        //     title: 'Employees',
        //     icon: icons.IconUsers,
        //     type: 'item',
        //     url: '/employees-page'
        // },
    ]
};

export default infoPages;
