const Cookies = require('js-cookie');
const CryptoJS = require('crypto-js');
const moment = require('moment');

export default class Cypher {
    encrypt(data) {
        return JSON.stringify({
            data: CryptoJS.AES.encrypt(
                JSON.stringify(data),
                process.env.REACT_APP_KEY // --- before deploy update here this should be used
                // 'genetrackbiolabs'
            ).toString()
        });
    }

    decrypt(data) {
        return JSON.parse(
            CryptoJS.AES.decrypt(
                data,
                process.env.REACT_APP_KEY // --- before deploy update here this should be used
                // 'genetrackbiolabs'
            ).toString(CryptoJS.enc.Utf8)
        );
    }

    hash(data) {
        return CryptoJS.SHA1(data).toString();
    }

    async saveToken(data, session) {
        localStorage.removeItem(session);
        const date = new Date(new Date().getTime() + 480 * 60 * 1000);
        Cookies.set(session, this.encrypt({ token: data.token, person: data.person }), {
            expires: date,
            sameSite: 'lax'
        });
        localStorage.setItem(session, moment(date).format('YYYY-MM-DD HH:mm:ss'));
        return true;
    }

    getUser(session) {
        const cook = Cookies.get(session);
        if (cook !== undefined) {
            return this.decrypt(JSON.parse(cook).data).person;
        } else {
            this.cleanSession(session);
        }
        return null;
    }

    updateUser(data, session) {
        const curr = this.decrypt(JSON.parse(Cookies.get(session)).data);
        curr.person = data;
        Cookies.set(session, this.encrypt(curr), {
            expires: new Date(new Date().getTime() + 480 * 60 * 1000),
            sameSite: 'lax'
        });
        return true;
    }

    async getToken(session) {
        const cook = Cookies.get(session);
        if (cook !== undefined) {
            return this.decrypt(JSON.parse(cook).data).token;
            // return this.decrypt(JSON.parse(cook).data).token.token;
        } else {
            localStorage.removeItem(session);
        }
        return null;
    }

    getSession(session) {
        let expire = localStorage.getItem(session);
        moment.suppressDeprecationWarnings = true;
        expire = moment().isBefore(moment(expire));
        moment.suppressDeprecationWarnings = false;
        if (expire === false) {
            localStorage.removeItem(session);
        }
        return expire;
    }

    cleanSession(session) {
        localStorage.removeItem(session);
        Cookies.remove(session);
        return true;
    }

    saveWPSession(data) {
        Cookies.set('wp_session', data, {
            expires: new Date(new Date().getTime() + 108000 * 60 * 1000),
            sameSite: 'lax'
        });
        return true;
    }

    getWPSession() {
        return Cookies.get('wp_session');
    }

    getCookieSession(key) {
        return Cookies.get(key);
    }

    setCookieSession(value, data) {
        Cookies.set(value, data, {
            expires: new Date(new Date().getTime() + 108000 * 60 * 1000),
            sameSite: 'lax'
        });
        return true;
    }

    removeCookieSession(key) {
        return Cookies.remove(key);
    }
}
