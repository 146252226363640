// assets
import { IconBuildingEstate, IconUsers, IconCalendarEvent } from '@tabler/icons';
import ListIcon from '@mui/icons-material/List';

const icons = {
    IconBuildingEstate,
    IconUsers,
    IconCalendarEvent,
    ListIcon
};

const dataPages = {
    id: 'data-pages',
    title: 'Data Pages',
    icon: icons.IconKey,
    type: 'group',
    children: [
        {
            id: 'facilities-page',
            title: 'Facilities',
            icon: icons.IconBuildingEstate,
            type: 'item',
            url: '/facilities-page'
        },
        {
            id: 'employees-page',
            title: 'Employees',
            icon: icons.IconUsers,
            type: 'item',
            url: '/employees-page'
        },
    ]
};

export default dataPages;
