// SALESdb
import infoPages from './info-pages';
// New section
import dataPages from './data-pages';

// LABdb inital sales
import labDbDataPages from './labdb-datas-pages';

// Dashboards
import adminDashboardPages from './admin-dashboard-pages';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [adminDashboardPages, infoPages, dataPages, labDbDataPages]
};

export default menuItems;
