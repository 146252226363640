import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';

// third-party
import { Chance } from 'chance';
// import jwtDecode from 'jwt-decode';

// reducer - state management
import { LOGIN, LOGOUT } from 'store/actions';
import accountReducer from 'store/accountReducer';

// project imports
import Loader from 'ui-component/Loader';
import axios from 'utils/axios';
import configAPI from '../config/api';
import CypherClass from '../config/Cypher';

const Cypher = new CypherClass();

const chance = new Chance();

// constant
const initialState = {
    isLoggedIn: false,
    isInitialized: false,
    user: null
};

const verifyToken = (serviceToken) => {
    if (!serviceToken) {
        return false;
    }
    // const decoded = jwtDecode(serviceToken);
    /**
     * Property 'exp' does not exist on type '<T = unknown>(token, options?: JwtDecodeOptions | undefined) => T'.
     */
    return new Date(serviceToken).getTime() > Date.now();
    //  / 1000;
};

// const setSession = (serviceToken) => {
//     if (serviceToken) {
//         localStorage.setItem('serviceToken', serviceToken);
//         axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
//     } else {
//         localStorage.removeItem('serviceToken');
//         delete axios.defaults.headers.common.Authorization;
//     }
// };

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //
const JWTContext = createContext(null);

export const JWTProvider = ({ children }) => {
    const [state, dispatch] = useReducer(accountReducer, initialState);

    useEffect(() => {
        const init = async () => {
            try {
                const serviceToken = window.localStorage.getItem('resolveTK');
                const user = Cypher.getUser('resolveTK');
                let session = Cypher.getSession('resolveTK');
                if (user && session && verifyToken(serviceToken)) {
                    // verified session
                    // setSession(serviceToken);
                    dispatch({
                        type: LOGIN,
                        payload: {
                            isLoggedIn: true,
                            user
                        }
                    });
                } else {
                    Cypher.cleanSession('resolveTK');
                    dispatch({
                        type: LOGOUT
                    });
                    if(window.location.href?.split("/")[3] !== "") {
                        window.location.href = "/";
                    }
                }
            } catch (err) {
                console.error(err);
                Cypher.cleanSession('resolveTK');
                dispatch({
                    type: LOGOUT
                });
                if(window.location.href?.split("/")[3] !== "") {
                        window.location.href = "/";
                }
            }
        };

        init();
    }, []);

    const login = async (email, password) => {
        const response = await fetch(`${configAPI.url}/sessions`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: Cypher.encrypt({ email, password })
        })
            .then((response) => response.text())
            .then((body) => {
                return Cypher.decrypt(body).data;
            });

        const { error, person, token } = response;
        if (error === false) {
            await Cypher.saveToken({ token: token?.token, person: person }, 'resolveTK');
            // setSession(token?.token);
            dispatch({
                type: LOGIN,
                payload: {
                    isLoggedIn: true,
                    person
                }
            });
        }
    };

    const register = async (email, password, firstName, lastName) => {
        // todo: this flow need to be recode as it not verified
        const id = chance.bb_pin();
        const response = await axios.post('/api/account/register', {
            id,
            email,
            password,
            firstName,
            lastName
        });
        let users = response.data;

        if (window.localStorage.getItem('users') !== undefined && window.localStorage.getItem('users') !== null) {
            const localUsers = window.localStorage.getItem('users');
            users = [
                ...JSON.parse(localUsers),
                {
                    id,
                    email,
                    password,
                    name: `${firstName} ${lastName}`
                }
            ];
        }

        window.localStorage.setItem('users', JSON.stringify(users));
    };

    const logout = async () => {
        const response = await fetch(`${configAPI.url}/sessions`, {
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${await Cypher.getToken('resolveTK')}`,
                'Content-Type': 'application/json'
            }
        })
            .then((response) => response.text())
            .then((body) => {
                return Cypher.decrypt(body).data;
            });

        if (response.error === false) {
            Cypher.cleanSession('resolveTK');
            dispatch({
                type: LOGOUT
            });
            window.location.href = "/";
        }

        //setSession(null);
    };

    const resetPassword = async (email) => {
        console.log(email);
    };

    const updateProfile = () => {};

    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    return (
        <JWTContext.Provider value={{ ...state, login, logout, register, resetPassword, updateProfile }}>{children}</JWTContext.Provider>
    );
};

JWTProvider.propTypes = {
    children: PropTypes.node
};

export default JWTContext;
