/**
 * axios setup to use mock service
 */

import axios from 'axios';
import CypherClass from '../config/Cypher.js';
const Cypher = new CypherClass();

// todo: delete or move this function when session management is in place
const baseUrl = () => {
    if (process.env.REACT_APP_IS_ONLINE === 'true') {
        return 'https://api.admin-db.com';
    } else {
        return 'http://127.0.0.1:3333';
    }
};

const axiosServices = axios.create({ baseURL: baseUrl() });

// interceptor for http
axiosServices.interceptors.response.use(
    (response) => response,
    (error) => Promise.reject((error.response && error.response.data) || 'Wrong Services')
);

// add authorization token for any request other than a post method request to /sessions
axiosServices.interceptors.request.use(
    async (config) => {
        const token = await Cypher.getToken('resolveTK');
        if (token && !(config.url == `/sessions` && config.method == 'post')) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

export default axiosServices;
