// assets
import { IconLayoutDashboard, IconHome } from '@tabler/icons';

const icons = {
    IconHome,
    IconLayoutDashboard
};

const adminDashboardPages = {
    id: 'admin-dashboard-pages',
    title: 'Admin Pages',
    icon: icons.IconKey,
    type: 'group',
    children: [
        {
            id: 'dashboard-page',
            title: 'Dashboard',
            icon: icons.IconHome,
            type: 'item',
            url: '/dashboard',
            breadcrumbs: false
        },
        {
            id: 'admin-dashboard-page',
            title: 'Admin Dashboard',
            icon: icons.IconLayoutDashboard,
            type: 'item',
            url: '/admin-dashboard-page'
        }
    ]
};

export default adminDashboardPages;
